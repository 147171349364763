import { useDroppable } from '@dnd-kit/core';
import { observer } from 'mobx-react-lite';
import type { Dispatch, JSX, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';

import type { Form, IFormConfig, IRowItem } from '@feathr/blackbox';
import { Spinner } from '@feathr/components';

import type { IFieldError } from '../FormEditor';
import DraggableFormElement from './DraggableFormElement';
import SubmitButton from './SubmitButton';

import * as styles from './Builder.css';

interface IProps {
  fields: IRowItem[];
  form: Form;
  onFocusField: Dispatch<SetStateAction<IRowItem | undefined>>;
  onDeleteField: () => void;
  validationErrors: IFieldError[];
  onFocusSubmitButton: Dispatch<SetStateAction<boolean>>;
}

function Builder({
  fields,
  form,
  onDeleteField,
  onFocusField,
  validationErrors,
  onFocusSubmitButton,
}: Readonly<IProps>): JSX.Element {
  const { setNodeRef: setDroppableRef } = useDroppable({
    id: 'form-builder',
  });
  const [config, setConfig] = useState<IFormConfig | undefined>(undefined);

  useEffect(() => {
    if (form.isPending) {
      return;
    }

    setConfig(form.formConfig);
  }, [form.isPending]);

  useEffect(() => {
    if (!config) {
      return;
    }

    setConfig({ ...config, settings: { submitLabel: form.formConfig.settings.submitLabel } });
  }, [form.formConfig.settings?.submitLabel]);

  // Set the updated config on the Form model as JSON.
  useEffect(() => {
    if (!config) {
      return;
    }

    const newConfig = {
      ...config,
      rows: fields.map((field) => ({ fields: [field] })),
    };

    // Don't save if there are validation errors
    if (validationErrors.length > 0) {
      return;
    }

    setConfig(newConfig);
    form.setConfig(newConfig);
  }, [fields, validationErrors]);

  function renderField(field: IRowItem): JSX.Element | null {
    if (field === undefined) {
      return null;
    }

    const fieldError = validationErrors.find((error) => error.fieldId === field.id);

    return (
      <DraggableFormElement
        error={fieldError?.message}
        field={field}
        isRemovable={field.id !== 'email'}
        key={field.id}
        onDeleteField={onDeleteField}
        onFocusField={onFocusField}
      />
    );
  }

  return (
    <div className={styles.root} data-name={'form-builder'} ref={setDroppableRef}>
      <div className={styles.content}>
        {config ? (
          <>
            {fields.map(renderField)}
            <SubmitButton
              label={form.formConfig.settings.submitLabel}
              setIsFocused={onFocusSubmitButton}
            />
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}

export default observer(Builder);
